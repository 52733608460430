.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: '600px';
  width: 100%;
  //min-width: '100%';
  flex: 1;
  height: '100%';
  //background-color: #f8f8ff;

  //border: 5px solid green;
  //background-image: linear-gradient(to right, #521485, #9e49e4);
}

.layoutLogoOnly {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: '80%';
  height: '100%';
  //background-color: #f8f8ff;
  //border: 5px solid green;
}
