/* Welldoing Minimal CSS reset */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul, ol {
    list-style: revert;
  }
  html {
    font-family: 'Montserrat', system-ui, sans-serif;
    box-sizing: border-box;
  }
}

.show-bar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.show-bar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

*:focus {
  box-shadow: none !important;
}

@layer components {
  .booking-list-curve {
    content: '';
    top: 0;
    bottom: 0;
    left: 11px;
    @apply absolute w-[2px] bg-slate-300 group-last:bg-transparent transition;
  }
  .booking-list-curve:before {
    content: '';
    left: 0;
    top: 0;
    bottom: calc(50% - 1px);
    @apply absolute rounded-bl-2xl border-b-2 border-l-2 border-slate-300 w-4 transition;
  }
  .dashboard-card {
    @apply bg-white rounded-2xl flex flex-col border border-[#DBDBDB] p-6 w-full overflow-hidden transition;
  }
}
